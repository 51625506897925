.wyswyg-editor {
  border-radius: 8px;
  height: 550px !important;
  overflow: scroll;
  padding: 15px;
  border: 1px solid var(--gray19);
}

.wyswyg-editor a:link,
.wyswyg-editor a:visited {
  color: inherit;
  text-decoration: underline;
  cursor: auto;
}

.wyswyg-editor a:link:active,
.wyswyg-editor a:visited:active {
  color: inherit;
}

.wyswyg-editor address {
  display: block;
  font-style: italic;
}

.wyswyg-editor area {
  display: none;
}

.wyswyg-editor article,
.wyswyg-editor aside,
.wyswyg-editor audio,
.wyswyg-editor base,
.wyswyg-editor bdi,
.wyswyg-editor bdo,
.wyswyg-editor blockquote,
.wyswyg-editor body,
.wyswyg-editor br,
.wyswyg-editor button,
.wyswyg-editor canvas,
.wyswyg-editor caption,
.wyswyg-editor cite,
.wyswyg-editor code,
.wyswyg-editor col,
.wyswyg-editor colgroup,
.wyswyg-editor datalist,
.wyswyg-editor dd,
.wyswyg-editor del,
.wyswyg-editor details,
.wyswyg-editor dfn,
.wyswyg-editor dialog,
.wyswyg-editor div,
.wyswyg-editor dl,
.wyswyg-editor dt,
.wyswyg-editor em,
.wyswyg-editor embed,
.wyswyg-editor fieldset,
.wyswyg-editor figcaption,
.wyswyg-editor figure,
.wyswyg-editor footer,
.wyswyg-editor form,
.wyswyg-editor h1,
.wyswyg-editor h2,
.wyswyg-editor h3,
.wyswyg-editor h4,
.wyswyg-editor h5,
.wyswyg-editor h6,
.wyswyg-editor head,
.wyswyg-editor header,
.wyswyg-editor hr,
.wyswyg-editor html,
.wyswyg-editor i,
.wyswyg-editor iframe,
.wyswyg-editor img,
.wyswyg-editor input,
.wyswyg-editor ins,
.wyswyg-editor kbd,
.wyswyg-editor label,
.wyswyg-editor legend,
.wyswyg-editor li,
.wyswyg-editor link,
.wyswyg-editor main,
.wyswyg-editor map,
.wyswyg-editor mark,
.wyswyg-editor menu,
.wyswyg-editor menuitem,
.wyswyg-editor meta,
.wyswyg-editor meter,
.wyswyg-editor nav,
.wyswyg-editor noscript,
.wyswyg-editor object,
.wyswyg-editor ol,
.wyswyg-editor optgroup,
.wyswyg-editor option,
.wyswyg-editor output,
.wyswyg-editor p,
.wyswyg-editor param,
.wyswyg-editor picture,
.wyswyg-editor pre,
.wyswyg-editor progress,
.wyswyg-editor q,
.wyswyg-editor rp,
.wyswyg-editor rt,
.wyswyg-editor ruby,
.wyswyg-editor s,
.wyswyg-editor samp,
.wyswyg-editor script,
.wyswyg-editor section,
.wyswyg-editor select,
.wyswyg-editor small,
.wyswyg-editor source,
.wyswyg-editor span,
.wyswyg-editor strike,
.wyswyg-editor strong,
.wyswyg-editor style,
.wyswyg-editor sub,
.wyswyg-editor summary,
.wyswyg-editor sup,
.wyswyg-editor table,
.wyswyg-editor tbody,
.wyswyg-editor td,
.wyswyg-editor template,
.wyswyg-editor textarea,
.wyswyg-editor tfoot,
.wyswyg-editor th,
.wyswyg-editor thead,
.wyswyg-editor time,
.wyswyg-editor title,
.wyswyg-editor tr,
.wyswyg-editor track,
.wyswyg-editor u,
.wyswyg-editor ul,
.wyswyg-editor var,
.wyswyg-editor video,
.wyswyg-editor wbr {
  /* Default styles */
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  outline: none;
}

.wyswyg-editor a:focus,
.wyswyg-editor body:focus,
.wyswyg-editor html:focus,
.wyswyg-editor iframe:focus {
  outline: none;
}

.wyswyg-editor blockquote {
  display: block;
  margin-top: 1em;
  margin-bottom: 1em;
  margin-left: 40px;
  margin-right: 40px;
}

.wyswyg-editor body {
  display: block;
  margin: 8px;
}

.wyswyg-editor body:focus,
.wyswyg-editor html:focus {
  outline: none;
}

.wyswyg-editor caption {
  display: table-caption;
  text-align: center;
}

.wyswyg-editor cite {
  font-style: italic;
}

.wyswyg-editor code {
  font-family: monospace;
}

.wyswyg-editor col {
  display: table-column;
}

.wyswyg-editor colgroup {
  display: table-column-group;
}

.wyswyg-editor datalist {
  display: none;
}

.wyswyg-editor dd {
  display: block;
  margin-left: 40px;
}

.wyswyg-editor del {
  text-decoration: line-through;
}

.wyswyg-editor details {
  display: block;
}

.wyswyg-editor dfn {
  font-style: italic;
}

.wyswyg-editor div {
  display: block;
}

.wyswyg-editor dl {
  display: block;
  margin-top: 1em;
  margin-bottom: 1em;
  margin-left: 0;
  margin-right: 0;
}

.wyswyg-editor dt {
  display: block;
}

.wyswyg-editor em {
  font-style: italic;
}

.wyswyg-editor embed:focus,
.wyswyg-editor iframe:focus {
  outline: none;
}

.wyswyg-editor fieldset {
  display: block;
  margin-left: 2px;
  margin-right: 2px;
  padding-top: 0.35em;
  padding-bottom: 0.625em;
  padding-left: 0.75em;
  padding-right: 0.75em;
  border: 2px groove;
}

.wyswyg-editor figcaption {
  display: block;
}

.wyswyg-editor figure {
  display: block;
  margin-top: 1em;
  margin-bottom: 1em;
  margin-left: 40px;
  margin-right: 40px;
}

.wyswyg-editor footer {
  display: block;
}

.wyswyg-editor form {
  display: block;
  margin-top: 0em;
}

.wyswyg-editor h1 {
  display: block;
  font-size: 2em;
  margin-top: 0.67em;
  margin-bottom: 0.67em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}

.wyswyg-editor h2 {
  display: block;
  font-size: 1.5em;
  margin-top: 0.83em;
  margin-bottom: 0.83em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}

.wyswyg-editor h3 {
  display: block;
  font-size: 1.17em;
  margin-top: 1em;
  margin-bottom: 1em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}

.wyswyg-editor h4 {
  display: block;
  margin-top: 1.33em;
  margin-bottom: 1.33em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}

.wyswyg-editor h5 {
  display: block;
  font-size: 0.83em;
  margin-top: 1.67em;
  margin-bottom: 1.67em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}

.wyswyg-editor h6 {
  display: block;
  font-size: 0.67em;
  margin-top: 2.33em;
  margin-bottom: 2.33em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}

.wyswyg-editor header {
  display: block;
}

.wyswyg-editor hr {
  display: block;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  margin-left: auto;
  margin-right: auto;
  border-style: inset;
  border-width: 1px;
}

.wyswyg-editor html {
  display: block;
}

.wyswyg-editor html:focus {
  outline: none;
}

.wyswyg-editor i {
  font-style: italic;
}

.wyswyg-editor iframe:focus {
  outline: none;
}

.wyswyg-editor iframe[seamless] {
  display: block;
}

.wyswyg-editor img {
  display: inline-block;
}

.wyswyg-editor ins {
  text-decoration: underline;
}

.wyswyg-editor kbd {
  font-family: monospace;
}

.wyswyg-editor label {
  cursor: default;
}

.wyswyg-editor legend {
  display: block;
  padding-left: 2px;
  padding-right: 2px;
  border: none;
}

.wyswyg-editor li {
  display: list-item;
}

.wyswyg-editor link {
  display: none;
}

.wyswyg-editor map {
  display: inline;
}

.wyswyg-editor mark {
  background-color: yellow;
  color: black;
}

.wyswyg-editor menu {
  display: block;
  list-style-type: disc;
  margin-top: 1em;
  margin-bottom: 1em;
  margin-left: 0;
  margin-right: 0;
  padding-left: 40px;
}

.wyswyg-editor menuitem {
  display: block;
}

.wyswyg-editor meta {
  display: none;
}

.wyswyg-editor meter {
  display: inline-block;
}

.wyswyg-editor nav {
  display: block;
}

.wyswyg-editor object {
  display: inline-block;
}

.wyswyg-editor ol {
  display: block;
  list-style-type: decimal;
  margin-top: 1em;
  margin-bottom: 1em;
  margin-left: 0;
  margin-right: 0;
  padding-left: 40px;
}