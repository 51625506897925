:root {
  --transparent: rgba(255, 255, 255, 0);
  --white: #fff;

  --black: #000;
  --black2: #13101f;
  --black3: #444752;
  --black4: #2b2b29;
  --black10: #2b2b29;

  --black-opacity: rgba(0, 0, 0, 0.5);
  --black-opacity2: rgba(19, 16, 31, 0.25);
  --black-opacity3: rgba(40, 31, 61, 0.11);

  --gray: #f5efff;
  --gray2: #b4b0bb;
  --gray3: #d9d7dd;
  --gray4: #e5e4e8;
  --gray5: #c4c4c4;
  --gray6: #7a7283;
  --gray7: #97909e;
  --gray8: #838383;
  --gray19: #c7c4c4;
  --gray22: #666666;
  --gray36: #666666;

  --gray-opacity: rgba(40, 31, 61, 0.16);
  --gray-opacity2: rgba(244, 242, 247, 0.6);

  --blue-opacity: rgba(40, 31, 61, 0.04);
  --blue-opacity2: rgba(40, 31, 61, 0.06);
  --blue-opacity3: rgba(212, 201, 236, 0.4);

  --red: #ed6c6c;
  --red2: #ffe4f4;

  --red-opacity: rgba(237, 108, 108, 0.1);

  --purple: #6b4ee6;
  --purple2: #c1b3f6;
  --purple3: #f0edfd;
  --purple4: #7a60e9;
  --purple5: #e1dcfa;
  --purple6: #f3f0fa;
  --purple7: #f4f1fa;
  --purple8: #eae4f6;
  --purple9: #bfafe3;
  --purple10: #dfd7f1;
  --purple11: #faf8fd;
  --purple12: #6246dc;
  --purple13: #9984f3;
  --purple14: #d8d0fc;
  --purple15: #fbfaff;

  --purple-opacity: rgba(250, 248, 253, 0.35);
  --purple-opacity2: rgba(86, 59, 142, 0.2);
  --purple-opacity3: rgba(250, 248, 253, 0.4);
  --purple-opacity4: rgba(240, 237, 253, 0.5);

  --pink: #d88dde;
  --pink2: #f9f8f9;

  --green: #51b89f;

  --green-opacity: rgba(81, 184, 159, 0.1);
}
